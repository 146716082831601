import React from 'react';

export default function Root () {

  return (
    <main>
      <div className="container-fluid slider_sec">
          <div id="banr_slider" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                  <div className="carousel-item active">
                      <img src={require("./img/home_bnr.jpg")} className="img-fluid d-block w-100" alt="Sharkfin Development" />
                      <div className="carousel-caption d-md-block">
                          <div className="row slid_bnr_sec">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12 wow slideInLeft">
                                  <div className="banr_ctxt">
                                      <div className="banr-txt-border">
                                          <h5>We Design Software</h5>
                                          <h2>We Build Dreams</h2>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-none d-sm-block">
                                  <div className="slide_ryt">
                                      <img alt="Sharkfin App Development" data-wow-delay="1.2s" className="hm_appbg wow fadeIn" src={require("./img/hm_bnr_bg.png")} />
                                      <img alt="Sharkfin App Development" data-wow-delay="0.2s" className="hm_apc1 wow fadeInRight" src={require("./img/img_slider2_mobile_02.png")} />
                                      <img alt="Sharkfin App Development" data-wow-delay="0.9s" className="hm_apc2 wow fadeInRight" src={require("./img/img_slider2_mobile_01.png")} />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="container-fluid whatdo_sec">
        <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-12 wow fadeInUp" data-wow-duration="2s">
                <div className="what_partnershp">
                    <img alt="What we do" className="img-fluid" src={require("./img/whatwedo_img.png")} />
                </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 col-12 wow fadeInUp" data-wow-duration="2s">
                <div className="whatwe_do">
                    <h2 className="wow fadeInDown">What we do</h2>
                    <hr className="wow fadeInDown" />
                    <p>Sharkfin Development is a Philadelphia based web and mobile software development company that provides IT solutions across the globe. With years of relevant experience, we focus on helping enterprise clients and startups build minimum viable products
                        (MVPs) that their users love to use.
                    </p>
                    <p>We offer a full suite of services that gives companies their best chance at success; building the best software to mitigate risks and maximize market opportunities.</p>
                </div>
            </div>
          </div>
      </div>

      <div className="container-fluid specl_sec" id="space_sec">
        <h2 className="wow fadeInDown" data-wow-duration="1s">Our Speciality</h2>
        <hr className="wow fadeInDown" data-wow-duration="1s" />
        <div className="row serve_row">
            <div className="col-lg-4 col-md-12 col-sm-6 col-12 mt-1 col_serve_box wow fadeInLeft">
                <div className="serve_icn">
                    <img alt="Sharkfin App Development" id="srv_pa1" className="img-fluid" src={require("./img/smartphone_1.png")} />
                    <img alt="Sharkfin App Development" id="srv_pa2" className="img-fluid" src={require("./img/smartphone.png")} />
                </div>
                <div className="serve_box text-center">
                    <h4>Mobile App Development</h4>
                    <p>We build apps that perform seamlessly across multiple platforms. There is no doubt that we provide the best "bang for your buck" among our industry counterparts.</p>
                    {/* <a href="mobile-app.html"><button className="btn btn-primary">Read More</button></a> */}
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-6 col-12 mt-1 col_serve_box wow fadeInDown">
                <div className="serve_icn">
                    <img alt="Sharkfin App Development" id="srv_pa1" className="img-fluid" src={require("./img/web_1.png")} />
                    <img alt="Sharkfin App Development" id="srv_pa2" className="img-fluid" src={require("./img/web.png")} />
                </div>
                <div className="serve_box text-center">
                    <h4>Web Development</h4>
                    <p>Need a website, progressive web app (PWA), landing page, single page application, or CMS? We have a responsive-first approach to design.</p>
                    {/* <a href="web-development.html"><button className="btn btn-primary">Read More</button></a> */}
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-6 col-12 mt-1 col_serve_box wow fadeInRight">
                <div className="serve_icn">
                    <img alt="Sharkfin App Development" id="srv_pa1" className="img-fluid" src={require("./img/setting_1.png")} />
                    <img alt="Sharkfin App Development" id="srv_pa2" className="img-fluid" src={require("./img/setting.png")} />
                </div>
                <div className="serve_box text-center">
                    <h4>Ui/ux Design</h4>
                    <p>We create visually stimulating, elegant, engaging, and innovative experiences that elevate your brand and positively impacting your mission</p>
                    {/* <a href="ui-ux-design.html"><button className="btn btn-primary">Read More</button></a> */}
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-6 col-12 mt-1 col_serve_box wow fadeInLeft">
                <div className="serve_icn">
                    <img alt="Sharkfin App Development" id="srv_pa1" className="img-fluid" src={require("./img/consulting1.png")} />
                    <img alt="Sharkfin App Development" id="srv_pa2" className="img-fluid" src={require("./img/consulting2.png")} />
                </div>
                <div className="serve_box text-center">
                    <h4>CONSULTING</h4>
                    <p>Make sure you talk to us if you’re getting ready to build your mobile app or site; we'll gladly walk you through what we’ve learned, what has worked for past clients.</p>
                    {/* <a href="contact-us.html"><button className="btn btn-primary">Read More</button></a> */}
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-6 col-12 mt-1 col_serve_box wow fadeInDown">
                <div className="serve_icn">
                    <img alt="Sharkfin App Development" id="srv_pa1" className="img-fluid" src={require("./img/browser_1.png")} />
                    <img alt="Sharkfin App Development" id="srv_pa2" className="img-fluid" src={require("./img/browser.png")} />
                </div>
                <div className="serve_box text-center">
                    <h4>Testing</h4>
                    <p>We provide a complete QA and testing department to implement strategies that cover the widest range of functional and usability tests. </p>
                    {/* <a href="quality-anlysis.html"><button className="btn btn-primary">Read More</button></a> */}
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-6 col-12 mt-1 col_serve_box wow fadeInRight">
                <div className="serve_icn">
                    <img alt="Sharkfin App Development" id="srv_pa1" className="img-fluid" src={require("./img/digital-marketing_1.png")} />
                    <img alt="Sharkfin App Development" id="srv_pa2" className="img-fluid" src={require("./img/digital-marketing.png")} />
                </div>
                <div className="serve_box text-center">
                    <h4>Digital Marketing</h4>
                    <p>Our digital marketing packages includes SEO, Social Media, Adwords/PPC services with a rich, artful experience and excellent technical expertise.</p>
                    {/* <a href="digital-marketing.html"><button className="btn btn-primary">Read More</button></a> */}
                </div>
            </div>
        </div>
      </div>

      <div className="container-fluid choose_sec" id="space_sec">
          <h2 className="wow fadeInDown">Why Choose Us</h2>
          <hr className="wow fadeInDown" />
          <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-1 wow fadeInLeft">
                  <div className="choose_box text-center">
                      <div className="choose_icn">
                          <img alt="Sharkfin App Development" className="img-fluid" src={require("./img/skills.png")} />
                      </div>
                      <h3>DIVERSITY OF SKILLS</h3>
                      <p>Diversity in skillset is another important factor why companies choose us as their development partner. You get to work with talented and diligent resources who are working on their technology advancement
                      </p>
                  </div>
                  <div className="choose_dotted">
                      <img alt="Sharkfin App Development" src={require("./img/dotted_choose.png")} />
                  </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-1 wow fadeInDown">
                  <div className="choose_box text-center">
                      <div className="choose_icn">
                          <img alt="Sharkfin App Development" className="img-fluid" src={require("./img/our_expertise.png")} />
                      </div>
                      <h3>OUR EXPERIENCE</h3>
                      <p>With our track record, companies are comfortable knowing that we deliver! We continuously implement iterative processes to check your app’s functionality and compliance with the latest market trends…</p>
                  </div>
                  <div className="choose_dotted2">
                      <img alt="Sharkfin App Development" src={require("./img/dotted_choose2.png")} />
                  </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-1 wow fadeInRight">
                  <div className="choose_box text-center">
                      <div className="choose_icn">
                          <img alt="Sharkfin App Development" className="img-fluid" src={require("./img/money-bag.png")} />
                      </div>
                      <h3>COST</h3>
                      <p>If you are looking for quality, the size of your organization shouldn’t dictate your selection of the app development company you entrust to execute your vision. We thrive at the intersection of Quality and Value!
                      </p>
                  </div>
              </div>
          </div>
      </div>

    </main>
  )
}
