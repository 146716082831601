import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: { shade: '#213D70', main: '#36528C', tint1: '#5E75A3', tint2: '#AFBAD1', tint3: '#F3C111' },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif'
        ].join(','),
        h1: {
            color: 'inherit',
            fontSize: '40px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            letterSpacing: '0.4px',
            lineHeight: '44px'
        }
    }
});