import React from 'react';

export default function Header () {

  return (
    <section id="header_sec" style={{borderBottom: 'solid 1px #ccc'}}>
    <div className="container-fluid">
        <div className="header_menu">
            <nav className="navbar navbar-expand-lg navbar-dark">
                <a className="navbar-brand" href="index.html">
                  <img alt="Sharkfin" className="img-fluid" style={{width: 184}} src={require('./img/logo.png')} />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link active" href="index.html">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="about.html">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="why-choose-us.html">Why Choose Us</a>
                        </li>
                        <li className="nav-item dropdown dropdown-hover">
                            <a className="nav-link dropdown-toggle" href={'./'} data-toggle="dropdown" aria-haspopup="true">
                                Services
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a href="mobile-app.html" className="dropdown-item nav-link">Mobile Apps</a>
                                <a href="ui-ux-design.html" className="dropdown-item nav-link">UI/UX Design</a>
                                <a href="web-development.html" className="dropdown-item nav-link">Web Development</a>
                                <a href="quality-anlysis.html" className="dropdown-item nav-link">Quality Analysis</a>
                            </div>
                        </li>
                    </ul>
                </div> */}
            </nav>
        </div>
    </div>
</section>
  )
}
