import React from 'react';
import './App.css';
import theme from './themes/theme';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Header from './header';
import Footer from './footer';
import Root from './Root';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { paths } from './utils/paths';

function App () {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div>
        <BrowserRouter>
          <Switch>
            <Route path={paths.root} exact component={Root} />
            <Redirect to={paths.root} />
          </Switch>
        </BrowserRouter>
      </div>
      <Footer />
    </MuiThemeProvider>
  );
}

export default App;