import React from 'react';

export default function Footer () {
  return (
    <>
    <div className="container-fluid all_right_foot" id="space_sec">
        <div className="row foot_pos" style={{display: 'flex', justifyContent: 'space-between'}}>
            <h6>&copy; {new Date().getFullYear()} Sharkfin Development
                <div style={{fontSize: 10}}>A Gainsborough Technologies Group Company</div>
            </h6>
            <h6>Contact Us
                <div style={{fontSize: 10}}>
                    <a style={{color: '#FFF'}} href={'mailto:hello@sharkfindevelopment.com'}> hello@sharkfindevelopment.com</a>
                </div>
            </h6>
        </div>
    </div>
    </>
  )
}
